var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Highlight Dates", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Highlight dates in datepicker using function with "),
        _c("code", [_vm._v("highlighted")]),
        _vm._v(" prop"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [_c("datepicker", { attrs: { highlighted: _vm.highlightedFn } })],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <datepicker :highlighted=\"highlightedFn\"></datepicker>\n</template>\n\n<script>\nimport Datepicker from 'vuejs-datepicker';\n\nexport default {\n  data() {\n    return {\n      highlightedFn: {\n        customPredictor(date) {\n          if (date.getDate() % 4 === 0) {\n            return true;\n          }\n        }\n      },\n    }\n  },\n  components: {\n    Datepicker\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }