var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Open Programatically", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Open datepicker programatically using "),
        _c("strong", [_vm._v("ref")]),
        _vm._v(" and "),
        _c("strong", [_vm._v("click-event")]),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mb-4",
              on: {
                click: function ($event) {
                  return _vm.$refs.programaticOpen.showCalendar()
                },
              },
            },
            [_vm._v("Open Picker")]
          ),
          _c("datepicker", { ref: "programaticOpen" }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-button class="mb-4" @click="$refs.programaticOpen.showCalendar()">Open Picker</vs-button>\n  <datepicker ref="programaticOpen"></datepicker>\n</template>\n\n<script>\nimport Datepicker from \'vuejs-datepicker\';\n\nexport default {\n  components: {\n    Datepicker\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }