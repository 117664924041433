import { render, staticRenderFns } from "./Datepicker.vue?vue&type=template&id=36bb873c&"
import script from "./Datepicker.vue?vue&type=script&lang=js&"
export * from "./Datepicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36bb873c')) {
      api.createRecord('36bb873c', component.options)
    } else {
      api.reload('36bb873c', component.options)
    }
    module.hot.accept("./Datepicker.vue?vue&type=template&id=36bb873c&", function () {
      api.rerender('36bb873c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/extra-components/datepicker/Datepicker.vue"
export default component.exports