var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Inline", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Create inline datepicker using "),
        _c("code", [_vm._v("inline")]),
        _vm._v(" prop"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [_c("datepicker", { attrs: { inline: true } })],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <datepicker :inline=\"true\"></datepicker>\n</template>\n\n<script>\nimport Datepicker from 'vuejs-datepicker';\n\nexport default {\n  components: {\n    Datepicker\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }