var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Format Datepicker", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Change datepicker format with "),
        _c("code", [_vm._v("format")]),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("datepicker", { attrs: { format: _vm.format } }),
          _c(
            "vs-select",
            {
              staticClass: "mt-4 w-full",
              model: {
                value: _vm.format,
                callback: function ($$v) {
                  _vm.format = $$v
                },
                expression: "format",
              },
            },
            _vm._l(_vm.formatOptions, function (option, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: { value: option.value, text: option.text },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <datepicker :format="format"></datepicker>\n  <vs-select v-model="format" class="mt-4 w-full">\n    <vs-select-item :key="index" :value="option.value" :text="option.text" v-for="(option,index) in formatOptions" />\n  </vs-select>\n</template>\n\n<script>\nimport Datepicker from \'vuejs-datepicker\';\n\nexport default {\n  data() {\n    return {\n      format: "d MMMM yyyy",\n      formatOptions:[\n        {text: "d MMM yyyy - e.g 12 Feb 2016" , value: "d MMM yyyy" },\n        {text: "d MMMM yyyy - e.g 12 February 2016", value: "d MMMM yyyy" },\n        {text: "yyyy-MM-dd - e.g 2016-02-12", value: "yyyy-MM-dd" },\n        {text: "dsu MMM yyyy - e.g 12th Feb 2016", value: "dsu MMM yyyy" },\n        {text: "D dsu MMM yyyy - e.g Sat 12th Feb 2016", value: "D dsu MMM yyyy" },\n      ],\n    }\n  },\n  components: {\n    Datepicker\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }