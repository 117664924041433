var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Min-Max Date Range", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can add min max date range to disable dates in number of ways: "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://github.com/charliekassel/vuejs-datepicker#disabled-dates",
              target: "_blank",
              rel: "nofollow",
            },
          },
          [_vm._v("docs")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [_c("datepicker", { attrs: { disabledDates: _vm.disabledDates } })],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <datepicker :disabledDates=\"disabledDates\"></datepicker>\n</template>\n\n<script>\nimport Datepicker from 'vuejs-datepicker';\n\nexport default {\n  data() {\n    return {\n      disabledDates: {\n        to: new Date(new Date().getFullYear(), new Date().getMonth()-2, 5), // Disable all dates up to specific date\n        from: new Date(new Date().getFullYear(), new Date().getMonth()+2, 26), // Disable all dates after specific date\n        daysOfMonth: [8, 11, 17], // Disable Specific days\n      }\n    }\n  },\n  components: {\n    Datepicker\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }