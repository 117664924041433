var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "extra-component-datepicker-demo" } }, [
    _vm._m(0),
    _c("div", { staticClass: "vx-row match-height" }, [
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-default", { staticClass: "mb-base" })],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-format", { staticClass: "mb-base" })],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row match-height" }, [
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-min-max-date-range", { staticClass: "mb-base" })],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-highlight-dates", { staticClass: "mb-base" })],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row match-height" }, [
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-inline", { staticClass: "mb-base" })],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-open-programatically", { staticClass: "mb-base" })],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row match-height" }, [
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-translation", { staticClass: "mb-base" })],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-rtl", { staticClass: "mb-base" })],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row match-height" }, [
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-day-view-only", { staticClass: "mb-base" })],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-month-view-only", { staticClass: "mb-base" })],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row match-height" }, [
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-date-month-view-only")],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full" },
        [_c("datepicker-month-year-view-only")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-4" }, [
      _vm._v(
        "A simple Vue.js datepicker component. Supports disabling of dates, inline mode, translations. Read full documnetation "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/charliekassel/vuejs-datepicker",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("here")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }