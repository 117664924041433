var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Translation", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "Date picker language: " +
            _vm._s(_vm.languages[_vm.language].language)
        ),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "vs-select",
            {
              staticClass: "mb-4 w-1/3",
              model: {
                value: _vm.language,
                callback: function ($$v) {
                  _vm.language = $$v
                },
                expression: "language",
              },
            },
            _vm._l(_vm.languages, function (language, key) {
              return _c("vs-select-item", {
                key: key,
                attrs: { value: key, text: language.language },
              })
            }),
            1
          ),
          _c("datepicker", {
            attrs: {
              language: _vm.languages[_vm.language],
              format: "d MMMM yyyy",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-select v-model="language" class="mb-4 w-1/3">\n    <vs-select-item :key="key" :value="key" :text="language.language" v-for="(language, key) in languages" />\n  </vs-select>\n  <datepicker :language="languages[language]" format="d MMMM yyyy"></datepicker>\n</template>\n\n<script>\nimport Datepicker from \'vuejs-datepicker\';\nimport * as lang from \'vuejs-datepicker/src/locale\';\n\nexport default {\n  data() {\n    return {\n      language: "en",\n      languages: lang,\n    }\n  },\n  components: {\n    Datepicker\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }