var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Month View Only", "code-toggler": "" } },
    [
      _c("p", [_vm._v("Rendering default DatePicker with Month view only")]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("datepicker", {
            attrs: { minimumView: "month", maximumView: "month" },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <datepicker :minimumView=\"'month'\" :maximumView=\"'month'\"></datepicker>\n</template>\n\n<script>\nimport Datepicker from 'vuejs-datepicker';\n\nexport default {\n  components: {\n    Datepicker\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }