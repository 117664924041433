var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "RTL Datepicker", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Rendering RTL DatePicker using "),
        _c("code", [_vm._v("language")]),
        _vm._v(" prop"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [_c("datepicker", { attrs: { language: _vm.languages.he } })],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <datepicker :language="languages.he"></datepicker>\n</template>\n\n<script>\nimport Datepicker from \'vuejs-datepicker\';\nimport * as lang from "vuejs-datepicker/src/locale";\n\nexport default {\n  data() {\n    return {\n      languages: lang,\n    }\n  },\n  components: {\n    Datepicker\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }